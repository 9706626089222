export default {
  accordion: {
    selector: '.accordion',
    method: 'scroll'
  },
  actionSection: {
    selector: '.action-section',
    method: 'scroll'
  },
  announcementV2: {
    selector: '.announcement-multi',
    method: 'load'
  },
  // addAnimations: {
  //   selector: '.has-animation',
  //   method: 'load'
  // },
  articlePost: {
    selector: '.article-post',
    method: 'scroll'
  },
  authorSection: {
    selector: '.author-section-content',
    method: 'scroll'
  },
  awardSection: {
    selector: '.award-section',
    method: 'scroll'
  },
  awardSlider: {
    selector: '.award-slider',
    method: 'scroll'
  },
  bingoCard: {
    selector: '.bingo-section',
    method: 'scroll'
  },
  communitySection: {
    selector: '.community-section',
    method: 'scroll'
  },
  comparisonSlider: {
    selector: '.comparison-slider',
    method: 'scroll'
  },
  countdown: {
    selector: '.countdown-section',
    method: 'scroll'
  },
  customerSlider: {
    selector: '.customer-section',
    method: 'scroll'
  },
  customerStories: {
    selector: '.case-study-overview',
    method: 'load'
  },
  customerSuccessSlider: {
    selector: '.customer-success',
    method: 'scroll'
  },
  customSelect: {
    selector: 'select',
    method: 'scroll'
  },
  embedVideo: {
    selector: '.video-holder',
    method: 'scroll',
  },
  eventsListing: {
    selector: '.events-listing',
    method: 'scroll'
  },
  eventsSection: {
    selector: '.webinars-section',
    method: 'scroll'
  },
  eventsSlider: {
    selector: '.wrap-events-slider',
    method: 'scroll'
  },
  featuresSlider: {
    selector: '.wrap-features-slider, .features-section, .product-features, .partners-list, .comparison-section',
    method: 'scroll'
  },
  comparisonSection: {
    selector: '.comparison-section',
    method: 'scroll'
  },
  featuredProducts: {
    selector: '.featured-products',
    method: 'scroll'
  },
  featuredResources: {
    selector: '.wrap-featured-resources',
    method: 'scroll'
  },
  filterFusionSchedule: {
    selector: '#filter-fusion',
    method: 'load'
  },
  filterGroup: {
    selector: 'form.filter',
    method: 'scroll'
  },
  filterJobs: {
    selector: '#filter-jobs',
    method: 'load'
  },
  footer: {
    selector: '.footer',
    method: 'load'
  },
  heroCarousel: {
    selector: '.hero-carousel',
    method: 'load'
  },
  industriesSection: {
    selector: '.industries-section',
    method: 'scroll'
  },
  imagesSlider: {
    selector: '.images-slider',
    method: 'scroll'
  },
  leadershipSection: {
    selector: '.leadership-section',
    method: 'scroll'
  },
  logosSlider: {
    selector: '.logos-section',
    method: 'scroll',
    options: {
      slider: {
        '1024': {
          // allowTouchMove: false,
          speed: 10000,
          loop: true,
          autoplay: {
            delay: 0,
            // disableOnInteraction: false
          },
          // slidesPerView: 4
        }
      }
    }
  },
  marketoForm: {
    selector: 'form[id^="mktoForm_"]',
    method: 'scroll'
  },
  newsSlider: {
    selector: '.news-slider-section',
    method: 'scroll'
  },
  ourSolutions: {
    selector: '.our-solutions',
    method: 'scroll'
  },
  parallax: {
    selector: '.parallax',
    method: 'scroll'
  },
  podcastVideo: {
    selector: '.podcast-video-wrapper',
    method: 'scroll'
  },
  categoriesSection: {
    selector: '.categories-section',
    method: 'scroll'
  },
  popups: {
    selector: '.open-popup, .open-video-popup',
    method: 'click'
  },
  postListingItem: {
    selector: '.post-listing-item',
    method: 'scroll'
  },
  postSection: {
    selector: '.post-section',
    method: 'scroll'
  },
  pricingSection: {
    selector: '.pricing-section',
    method: 'scroll'
  },
  productComparisonSlider: {
    selector: '.product-comparison-slider',
    method: 'scroll'
  },
  productFeatures: {
    selector: '.product-features, .partners-list',
    method: 'scroll'
  },
  propsSlider: {
    selector: '.wrap-props-slider',
    method: 'scroll'
  },
  quoteSlider: {
    selector: '.quote, .quote-section',
    method: 'scroll'
  },
  resourceSection: {
    selector: '.resource-section',
    method: 'scroll'
  },
  resultsSection: {
    selector: '.results-section',
    method: 'scroll'
  },
  resultsSlider: {
    selector: '.results-slider',
    method: 'scroll'
  },
  reviews: {
    selector: '.reviews-section',
    method: 'scroll',
    options: {
      slider: {
        targetSelector: '.reviews-slider-quotes',
        '1200': {
          slidesPerView: 3
        },
        '1440': {
          slidesPerView: 3
        },
        '1800': {
          slidesPerView: 3
        }
      }
    }
  },
  serveSection: {
    selector: '.serve-section',
    method: 'scroll'
  },
  socialSection: {
    selector: '.social',
    method: 'social'
  },
  shortProducts: {
    selector: '.header-bottom',
    method: 'mousedown'
  },
  sliderGallery: {
    selector: '.gallery-section',
    method: 'scroll'
  },
  styleguideSection: {
    selector: '.styleguide-section',
    method: 'scroll'
  },
  tabsSection: {
    selector: '.tabs-section',
    method: 'scroll'
  },
  teltContact: {
    selector: '.telt-contact-widget',
    method: 'scroll'
  },
  eventTimetable: {
    selector: '.wrap-event-timetable',
    method: 'scroll'
  },
  fusion: {
    selector: '.wrap-fusion-schedule',
    method: 'scroll'
  },
  videoSlider: {
    selector: '.video-slider',
    method: 'scroll'
  },
  vidyardEmbed: {
    selector: '.vidyard-player-embed',
    method: 'scroll'
  },
  requestSection: {
    selector: '.request-section',
    method: 'scroll'
  },
  singlePostForm: {
    selector: '.single #mktoForm_1370',
    method: 'scroll'
  },
  timelineSection: {
    selector: '.timeline-section',
    method: 'scroll',
    options: {
      slider: {
        spaceBetween: 0,
        '1200': {
          slidesPerView: 3,
          spaceBetween: 0
        },
        '1440': {
          slidesPerView: 3,
          spaceBetween: 0
        },
        '1800': {
          spaceBetween: 0
        }
      }
    }
  },
  trynowForm: {
    selector: '.single-trynow-sandbox',
    method: 'load'
  },
  productComparisonSection: {
    selector: '.product-comparison',
    method: 'scroll'
  },
  // demoSection: {
  //   selector: '.demo-section',
  //   method: 'scroll'
  // },
  demoRequest: {
    selector: '.demo-request',
    method: 'scroll',
    options: {}
  },
  eventInfo: {
    selector: '.event-info',
    method: 'scroll'
  },
  benefitsSection: {
    selector: '.benefits-section',
    method: 'scroll'
  },
  benefitsSolutions: {
    selector: '.benefits-solutions',
    method: 'scroll'
  },
  productInfo: {
    selector: '.product-info',
    method: 'scroll'
  },
  propsSection: {
    selector: '.props-section, .props-columns',
    method: 'scroll'
  },
  infoSection: {
    selector: '.info-section',
    method: 'scroll'
  },
  blackSection: {
    selector: '.black-section',
    method: 'scroll'
  },
  columnsSection: {
    selector: '.columns-3-section',
    method: 'scroll'
  },
  lottiePlayer: {
    selector: '.lottie-wrapper',
    method: 'scroll'
  }
};
